import React from "react";
import PropTypes from "prop-types";

const ArrowRight = ({ onClick }) => (
  <svg
    className="arrow-right"
    onClick={onClick}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="-0.000488281"
      width="64"
      height="64"
      rx="32"
      fill="#1769FF"
      fillOpacity="0.3"
    />
    <path
      d="M37.3711 32.48C37.5859 32.3081 37.7148 32.0503 37.7148 31.7495C37.7148 31.4917 37.5859 31.2339 37.3711 31.019L29.0352 22.6831C28.8203 22.5112 28.5625 22.3823 28.3047 22.3823C28.0039 22.3823 27.7461 22.5112 27.5742 22.6831L26.6289 23.6284C26.4141 23.8433 26.2852 24.1011 26.2852 24.3589C26.2852 24.6597 26.3711 24.9175 26.5859 25.0894L33.2461 31.7495L26.5859 38.4097C26.3711 38.6245 26.2852 38.8823 26.2852 39.1401C26.2852 39.4409 26.4141 39.6987 26.6289 39.8706L27.5742 40.8159C27.7461 41.0308 28.0039 41.1167 28.3047 41.1167C28.5625 41.1167 28.8203 41.0308 29.0352 40.8159L37.3711 32.48Z"
      fill="#1769FF"
    />
  </svg>
);

ArrowRight.propTypes = {
  onClick: PropTypes.func,
};
ArrowRight.defaultProps = {
  onClick: null,
};

export default ArrowRight;
