import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import successImage from "../../images/svg/success_party.svg";
import demoBg from "../../images/global_assets/thank-you_bg.png";
import PrimaryCTA from "../Buttons/primary-cta";
import Heading from "../../components-v2/Base/HeadingBuilder";

const ThankYou = (props) => {
  const {
    thankYouMessage,
    thankYouSubheader,
    phoneText,
    phoneNumber,
    ctaPrimary,
    noPhoneMessage,
    customCta,
    withName,
  } = props;
  const [clientFirstName, setClientFirstName] = useState("");

  useEffect(() => {
    setClientFirstName(window.sessionStorage.getItem("Name"));
  });
  return (
    <div className="sc">
      {/* <Image
        imageName="thank-you_bg.png"
        alt="image with happy business owners"
        style={{ width: 500 }}
      /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          marginBottom: 100,
        }}
      >
        <img
          src={successImage}
          alt="checkmark"
          style={{ margin: "auto", maxWidth: "40%", marginTop: 30 }}
        />
        <div className="sc__h2 first">
          {withName ? (
            <Heading injectionType={2} level={1}>
              Thank you{clientFirstName && ", "}
              <span style={{ color: "#1769FF" }}>{clientFirstName}</span>!
            </Heading>
          ) : (
            <Heading injectionType={2} level={1}>
              Thank you!
            </Heading>
          )}
        </div>
        <div
          className="text-[20px] text-black-300 whitespace-pre-line mt-4 max-w-[600px] text-center"
          dangerouslySetInnerHTML={{ __html: thankYouMessage }}
        />

        {!noPhoneMessage && (
          <div className="sc__h3">
            <div dangerouslySetInnerHTML={{ __html: thankYouSubheader }} />
            <br />
            <br />
            {phoneNumber && (
              <a
                href={`tel:${phoneNumber}`}
                className="btn-solid-dark"
                style={{ fontWeight: "bold" }}
              >
                {phoneText}
              </a>
            )}
            {ctaPrimary && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <PrimaryCTA
                  ctaTitle="Go to Dashboard"
                  target="https://login.spoton.com/home/"
                />
              </div>
            )}
          </div>
        )}

        {customCta && (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="mt-10 lg:mt-20"
          >
            {customCta}
          </div>
        )}
      </div>

      <style jsx global>{`
        .sc {
          position: relative;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          max-width: 1440px;
          margin: auto;
          margin-bottom: 40px;
          background-image: url(${demoBg});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        .sc .sc__check {
          position: relative;
          margin-top: 50px;
        }

        .sc .sc__h3 {
          margin-top: 20px;
          position: relative;
          font-style: normal;
          font-size: 26px;
          color: black;
          text-align: center;
          padding: 0 20px;
          max-width: 700px;
        }

        .sc .sc__h2 {
          position: relative;
          font-style: normal;
          font-weight: 600;
          font-size: 38px;
          line-height: 48px;
          color: black;
          text-align: center;
          margin-top: 10px;
        }
        .sc .sc__h2.first {
          margin-top: 50px;
        }

        .sc .sc__h4 {
          position: relative;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: black;
          margin-bottom: 170px;
          padding: 30px;
          text-align: center;
        }

        .sc .sc__phone {
          text-decoration: none;
          font-weight: bold;
        }
        .sc .sc__phone:hover {
          text-decoration: underline;
        }

        .sc .sc__background {
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          bottom: 0;
        }

        @media screen and (min-width: 960px) {
          .sc .sc__h2 {
            font-size: 48px;
          }
        }
      `}</style>
    </div>
  );
};
ThankYou.propTypes = {
  thankYouMessage: PropTypes.string,
  thankYouSubheader: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneText: PropTypes.string,
  ctaPrimary: PropTypes.bool,
  noPhoneMessage: PropTypes.bool,
  customCta: PropTypes.element,
  withName: PropTypes.bool,
};

ThankYou.defaultProps = {
  thankYouMessage: `A SpotOn representative will connect with you shortly!`,
  thankYouSubheader: `You can also call us now at`,
  phoneNumber: "+18005640174",
  phoneText: "(800) 564-0174",
  ctaPrimary: false,
  noPhoneMessage: false,
  customCta: null,
  withName: true,
};

export default ThankYou;
