import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Carousel from "nuka-carousel";
import { Typography } from "antd";

import useWindowSize from "../../hooks/use-window-size";
import ReviewCard from "./ReviewCard";
import ArrowLeft from "../CarouselCustomControls/ArrowLeft";
import ArrowRight from "../CarouselCustomControls/ArrowRight";
import TestimonialReviewsStyles from "./TestimonialReviewsStyles";

const { Title } = Typography;

const TestmonialReviews = (props) => {
  const { sectionData, className, heightMode } = props;
  const { title, reviews, subTitle, punctuation } = sectionData;
  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const [cellSpacing, setCellSpacing] = useState(10);
  const size = useWindowSize();

  useEffect(() => {
    const { width } = size;
    const isMobileOnly = width <= 576;
    const isTablet = width > 576 && width < 992;
    const isSreenLG = width > 992 && width < 1200;
    const screenXL = width > 1200;

    const slides = () => {
      if (isMobileOnly) return 1.1;
      if (isTablet) return 1.5;
      if (isSreenLG) return 2.8;
      if (screenXL) return 2.8;
      return 2.8;
    };
    const spacing = () => {
      if (isMobileOnly) return 10;
      if (isTablet) return 30;
      return 50;
    };

    setNumberOfSlides(slides);
    setCellSpacing(spacing);
  }, [size]);

  return (
    <section className={`testimonial-reviews z-0 ${className}`}>
      {/* eslint-disable-next-line no-bitwise */}
      {title && (
        <Title
          level={2}
          className="text-center mx-[8px]"
          style={{ marginBottom: "80px !important" }}
        >
          {title}
          <span className="blue_dot">{`${punctuation || "."}`}</span>{" "}
        </Title>
      )}
      {subTitle && (
        <p style={{ marginBottom: 32 }} className="text-center">
          {subTitle}
        </p>
      )}
      <div className="lateral-shadows">
        <Carousel
          slidesToShow={numberOfSlides}
          cellSpacing={cellSpacing}
          enableKeyboardControls
          heightMode={heightMode}
          // withoutControls
          dragging
          swiping
          wrapAround
          renderCenterLeftControls={({ previousSlide }) => (
            <ArrowLeft onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ArrowRight onClick={nextSlide} />
          )}
          renderBottomCenterControls={() => null}
          className="slider-adapted"
        >
          {reviews.map((review) => (
            <ReviewCard
              key={review.quote}
              quote={review.quote}
              reviewDate={review.reviewDate}
              reviewer={review.reviewer}
              reviewerBio={review.reviewerBio}
              rating={review.rating}
              reviewerPhoto={review.reviewerPhoto}
            />
          ))}
        </Carousel>
      </div>

      <TestimonialReviewsStyles />
    </section>
  );
};

TestmonialReviews.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  heightMode: PropTypes.string,
};
TestmonialReviews.defaultProps = {
  sectionData: "",
  className: "",
  heightMode: "max",
};

export default TestmonialReviews;
