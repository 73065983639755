import React from "react";
import { viewports } from "../../style-vars";

const { mdOrSmaller } = viewports;

const TestimonialReviewsStyles = () => (
  <style jsx global>{`
    .testimonial-reviews {
      position: relative;
      margin-bottom: 40px;
    }
    .testimonial-reviews ul.slider-list {
      margin-bottom: 80px !important;
    }
    h2 .ant-typography {
      margin-bottom: 80px !important;
      margin-top: 32px !important;
    }
    .testimonial-reviews h2 {
      font-weight: 800 !important;
      margin-bottom: 80px !important;
    }
    .lateral-shadows {
      position: relative;
    }
    .lateral-shadows:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -2px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, #ffffff, rgba(244, 244, 244, 0.1));
      z-index: 1;
    }
    section.testimonial-reviews .slider-control-centerleft {
      z-index: 10 !important;
    }
    section.testimonial-reviews .slider-control-centerright {
      z-index: 10 !important;
    }
    @media ${mdOrSmaller} {
      .lateral-shadows:before {
        background: transparent;
      }
    }
    .lateral-shadows:after {
      content: "";
      position: absolute;
      top: 0px;
      right: -2px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to left, #ffffff, rgba(244, 244, 244, 0.1));
      z-index: 1;
    }
    @media ${mdOrSmaller} {
      .lateral-shadows:after {
        background: transparent;
      }
    }
    .lateral-shadow-right {
      position: relative;
    }
    .lateral-shadow-right:after {
      content: "";
      position: absolute;
      top: 0px;
      right: -2px;
      width: 20%;
      height: 100%;
      background: linear-gradient(to left, #ffffff, rgba(244, 244, 244, 0.1));
      z-index: 1;
    }
    @media ${mdOrSmaller} {
      .lateral-shadow-right:after {
        background: transparent;
      }
    }
  `}</style>
);

export default TestimonialReviewsStyles;
